<template>
  <div class="stars__wrapper" :style="cssStyle">
    <canvas
      ref="canvasBackground"
      :aria-hidden="true"
      :width="canvasWidth"
      :height="canvasHeight"
      :style="{
        width: cssWidth + 'px',
        height: cssHeight + 'px'
      }"
    />
    <canvas
      ref="canvas"
      :aria-label="`${value} von 5 Sternen`"
      :width="canvasWidth"
      :height="canvasHeight"
      :style="{
        width: cssWidth + 'px',
        height: cssHeight + 'px'
      }"
    />
  </div>
</template>

<script>
import { isString } from 'src/lib/helpers'

export default {
  name: 'TrustedShopsStars',
  props: {
    width: {
      type: [String, Number],
      default: 100
    },
    spacing: {
      type: [String, Number],
      default: 5
    },
    value: {
      type: [String, Number],
      required: true,
      default: 4.3
    }
  },
  data() {
    return {
      count: 5,
      yellow: '#ffdc0f',
      grey: '#ccc',
      rating: this.parseValue(),
      widthInternal: parseInt(this.width),
      spacingInternal: parseInt(this.spacing),
      cssWidth: undefined,
      cssHeight: undefined
    }
  },
  computed: {
    canvasWidth() {
      return (
        this.widthInternal * this.count +
        this.spacingInternal * (this.count + 1)
      )
    },
    canvasHeight() {
      return this.widthInternal + this.spacingInternal
    },
    canvasBackground() {
      const canvas = this.$refs.canvasBackground

      return canvas.getContext('2d')
    },
    canvas() {
      const canvas = this.$refs.canvas

      return canvas.getContext('2d')
    },
    firstElementX() {
      return this.widthInternal / 2 + this.spacingInternal
    },
    cssStyle() {
      return {
        width: this.cssWidth + 'px',
        height: this.cssHeight + 'px',
        maxWidth: '100%'
      }
    }
  },
  mounted() {
    this.updateCssStyle()
    window.addEventListener('resize', this.updateCssStyle)

    this.drawStars(this.canvasBackground, this.grey)
    this.clipStars()
    this.drawStars(this.canvas, this.yellow)
  },
  methods: {
    parseValue() {
      if (isString(this.value) && this.value.includes(',')) {
        return parseFloat(this.value.replace(',', '.'))
      }

      return parseFloat(this.value)
    },
    drawStars(canvas, color) {
      for (let i = 0; i < this.count; i++) {
        const left =
          i === 0
            ? this.firstElementX
            : this.firstElementX +
              i * this.widthInternal +
              i * this.spacingInternal

        this.drawStar(
          canvas,
          left,
          this.widthInternal / 2 + this.spacingInternal,
          this.widthInternal / 2,
          this.widthInternal / 4,
          color
        )
      }
    },
    updateCssStyle() {
      this.cssWidth = Math.min(
        this.$el.getBoundingClientRect().width,
        this.canvasWidth
      )
      this.cssHeight = (this.cssWidth / this.canvasWidth) * this.canvasHeight
    },
    clipStars() {
      const completeStarsWidth =
        parseInt(this.rating) * (this.widthInternal + this.spacingInternal)
      const fractionalStarWith =
        (this.rating - parseInt(this.rating)) * this.widthInternal

      this.canvas.rect(
        0,
        0,
        this.spacingInternal + completeStarsWidth + fractionalStarWith,
        this.canvasHeight
      )
      this.canvas.clip()
    },
    drawStar(canvas, cx, cy, outerRadius, innerRadius, color) {
      let rot = (Math.PI / 2) * 3
      let x = cx
      let y = cy

      canvas.beginPath()
      canvas.moveTo(cx, cy - outerRadius)
      for (let i = 0; i < 5; i++) {
        x = cx + Math.cos(rot) * outerRadius
        y = cy + Math.sin(rot) * outerRadius
        canvas.lineTo(x, y)
        rot += Math.PI / 5
        x = cx + Math.cos(rot) * innerRadius
        y = cy + Math.sin(rot) * innerRadius
        canvas.lineTo(x, y)
        rot += Math.PI / 5
      }

      canvas.lineTo(cx, cy - outerRadius)
      canvas.closePath()
      canvas.lineWidth = 1
      canvas.strokeStyle = color
      canvas.stroke()
      canvas.fillStyle = color
      canvas.fill()
    }
  }
}
</script>

<style lang="scss" scoped>
canvas {
  position: absolute;
}
</style>
